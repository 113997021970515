#cps_container .hidden {
    visibility: hidden;
}

#cps_container .partners_message {
    margin-top: 1rem;
    color: #ccc;
    text-align: right;
    font-size: 14px;
}

#cps_container .message p {
    margin-bottom: 0;
}

#cps_container .p_cont a {
    text-decoration: none;
    color: black;
}

#cps_container .p_cont ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#cps_container .p_cont ul li {
    text-indent: 0 !important;
    display: inline-block;
    padding: 0.5%;
    width: 30%;
    margin: 15px 0.5% 0;
}

#cps_container .p_cont .item {
    position: relative;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    background: linear-gradient(360deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 3%);
    width: 95%;
}

#cps_container .p_cont .item:hover {
    box-shadow: 3px -3px #f6e05e;
}

#cps_container .p_cont .item .item-image {
    margin: 1rem;

}
#cps_container .p_cont .item .item-image img {
    width: 100%;
}

#cps_container .p_cont .item .item-name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#cps_container .p_cont .item .item-price {
    font-size: 1rem;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #eb4034;
    text-decoration-thickness: 3px;
    margin-top: 10px;
    padding: 5px 0 5px 0;
    border-top: 1px solid #eee;
}


#cps_container .p_cont .item .ranking {
    position: absolute;
    background-color: #f6e05e;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

#cps_container .p_cont .rocket-image {
    margin-top: 5px;
    height: 15px;
}

#cps_container .p_cont .rocket-image img {
    width: 60px;
    height: 15px;
}

@media (max-width:660px) {
    #cps_container .p_cont ul li { width: 28%; margin: 15px 1.5% 0; }
}

@media (max-width:500px) {
    #cps_container .p_cont ul li { width: 42%; margin: 15px 2.5% 0; }
}


/*
화면 보여주기용
 */
.p_cont .item .delete {
    position: absolute;
    margin-left: 78%;
}
