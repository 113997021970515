/*@import '~antd/dist/antd.css';*/

.site-layout .site-layout-header {
  background: #fff;
  padding: 0 15px;
  border-bottom: 1px solid #eaeaea;
}

.header-wrapper {
  max-width: 960px;
  height: 64px;
}

.site-layout .site-layout-content {
  padding: 15px;
}

.content-wrapper {
  max-width: 960px;
  margin: 0 auto;
}

.site-layout .site-layout-footer {
  background: #fff;
  padding: 15px;
}

.footer-wrapper {
  max-width: 960px;
  height: 48px;
}

.search-area {
  max-width: 240px;
}

.basket {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
}
.basket .ant-badge {
  position: fixed;
  bottom: 42px;
  right: 45px;
  padding: 30px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.login-form-wrapper {
  margin: 16px auto;
  border-radius: 16px;
  width: 320px;
  padding: 32px;
  background-color: #ffffff;

}

.ql-container {
  height: auto;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

@media (max-width: 576px) {
  .site-layout .site-layout-header {
    padding: 0 15px;
  }
  /*.search-area {*/
  /*  display: none;*/
  /*}*/
  /*.search-button {*/
  /*  display: inline-block;*/
  /*}*/
}
@media (min-width: 576px) {
  /*.search-button {*/
  /*  display: none;*/
  /*}*/
}
@media (min-width: 768px) {

}
@media (min-width: 992px) {

}
@media (min-width: 1200px) {

}