.button {
    align-items: center;
    background-clip: padding-box;
    background-color: #fa6400;
    border: 1px solid transparent;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 2.5rem;
    padding: calc(.6rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button:hover,
.button:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: #ffffff;
}

.button:hover {
    transform: translateY(-1px);
}

.button:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

#cps_container {
    font-size: 12px;
}

#cps_container .hidden {
    visibility: hidden;
}

#cps_container h1, h2, h3, h4, h5, h6 {
    margin: 4px 0;
}

#cps_container .content {
    max-width: 480px;
}

#cps_container .content {
    margin: 32px auto;
}

#cps_container .content a {
    text-decoration: none;
}

#cps_container .content .item-image img {
    width: 100%;
}

#cps_container .content .item {
    padding: 32px;
    border-radius: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#cps_container .content .item-info div {
    margin: 8px 0;
}

#cps_container .content .item-name span {
    padding: 5px 10px;
    background-color: #2f54eb;
    color: #ffffff;
    border-radius: 8px;
    font-size: 12px;
}

#cps_container .content .item-price {
    display: flex;
    gap: 12px;
    justify-content: left;
    align-items: center;
    color: #ff0000;
    font-weight: bold;
}

#cps_container .content .item-buttons {
    display: flex;
    gap: 12px;
    justify-content: left;
    align-items: center;
}

#cps_container .content .item-buttons {

}

#cps_container .content .item-description small {
    display: block;
    color: #999999;
}

#cps_container .content .rocket-image {
    height: 16px;
    margin-left: 10px;
}

@media (min-width: 576px) {
    #cps_container h1 {
        font-size: 20px;
        font-weight: bold;
    }

    #cps_container h2 {
        font-size: 16px;
        font-weight: bold;
    }
}

@media (max-width: 576px) {
    #cps_container h1, h2 {
        font-size: 16px;
        font-weight: bold;
    }
}