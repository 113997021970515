
  h1 {
    font-size: 1.5rem;
    line-height: 2rem
}
  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem
}
  h3 {
    font-size: 1.125rem;
    line-height: 1.75rem
}
  h4 {
    font-size: 1rem;
    line-height: 1.5rem
}
  h5 {
    font-size: 1rem;
    line-height: 1.5rem
}
  h6 {
    font-size: 0.875rem;
    line-height: 1.25rem
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::-webkit-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.container {
    width: 100%
}
@media (min-width: 576px) {
    .container {
        max-width: 576px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 992px
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px
    }
}
@media (min-width: 1600px) {
    .container {
        max-width: 1600px
    }
}
.visible {
    visibility: visible
}
.\!visible {
    visibility: visible !important
}
.static {
    position: static
}
.mx-auto {
    margin-left: auto;
    margin-right: auto
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}
.mb-6 {
    margin-bottom: 1.5rem
}
.mb-1 {
    margin-bottom: 0.25rem
}
.mb-0 {
    margin-bottom: 0px
}
.mt-4 {
    margin-top: 1rem
}
.block {
    display: block
}
.inline-block {
    display: inline-block
}
.flex {
    display: flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.hidden {
    display: none
}
.h-screen {
    height: 100vh
}
.w-1\/3 {
    width: 33.333333%
}
.flex-none {
    flex: none
}
.flex-initial {
    flex: 0 1 auto
}
.flex-auto {
    flex: 1 1 auto
}
.content-center {
    align-content: center
}
.items-center {
    align-items: center
}
.justify-center {
    justify-content: center
}
.justify-between {
    justify-content: space-between
}
.overflow-hidden {
    overflow: hidden
}
.overflow-visible {
    overflow: visible
}
.border-b {
    border-bottom-width: 1px
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.p-4 {
    padding: 1rem
}
.p-20 {
    padding: 5rem
}
.p-3 {
    padding: 0.75rem
}
.text-center {
    text-align: center
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.font-extrabold {
    font-weight: 800
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.filter {
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
@media (max-width: 576px) {
    .xs\:h-36 {
        height: 9rem
    }
    .xs\:w-full {
        width: 100%
    }
    .xs\:w-36 {
        width: 9rem
    }
}
@media (min-width: 576px) {
    .sm\:h-44 {
        height: 11rem
    }
    .sm\:w-44 {
        width: 11rem
    }
}
@media (min-width: 768px) {
    .md\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem
    }
}